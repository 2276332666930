// src/components/PredictionTable.js

import React from 'react';
import './PredictionTable.css';

const PredictionTable = ({ predictions }) => {
  return (
    <table className="prediction-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Coin</th>
          <th>Predicted Price</th>
        </tr>
      </thead>
      <tbody>
        {predictions.map((prediction) => (
          <tr key={prediction._id}>
            <td>{new Date(prediction.datePredicted).toLocaleString()}</td>
            <td>{prediction.coinSymbol}</td>
            <td>{prediction.predictedPrice}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PredictionTable;
