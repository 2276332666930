// src/components/PaymentForm.js

import React, { useState } from 'react';
import './PaymentForm.css';

const PaymentForm = ({ requestPayment, loading, isDisabled, network, account, awaitingConfirmation }) => {
  const [paymentMethod, setPaymentMethod] = useState('FLR');

  const handleSubmit = async (e) => {
    e.preventDefault();
    requestPayment(paymentMethod);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="payment-method">
        <label className="custom-radio">
          <input
            type="radio"
            value="FLR"
            checked={paymentMethod === 'FLR'}
            onChange={(e) => setPaymentMethod(e.target.value)}
          />
          <span className="radio-btn"></span>
          Pay with {network.symbol}
        </label>
        <label className="custom-radio">
          <input
            type="radio"
            value="FOTON"
            checked={paymentMethod === 'FOTON'}
            onChange={(e) => setPaymentMethod(e.target.value)}
          />
          <span className="radio-btn"></span>
          Pay with FOTON Token
        </label>
      </div>
      <button
        type="submit"
        className="make-payment-button"
        disabled={loading || isDisabled || awaitingConfirmation}
      >
        {awaitingConfirmation
          ? 'Awaiting Confirmation...'
          : loading
          ? 'Processing...'
          : paymentMethod === 'FLR'
          ? `Pay ${network.paymentAmount} ${network.symbol} to Predict`
          : `Pay 50 FOTON to Predict`}
      </button>
    </form>
  );
};

export default PaymentForm;
