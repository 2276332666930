// src/components/CoinDropdown.js

import React, { useState, useEffect, useMemo } from 'react';
import DynamicDropdown from './DynamicDropdown'; // Import the new component

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // Environment variable

const CoinDropdown = ({ selectedCoin, setSelectedCoin }) => {
  const [coinList, setCoinList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopCoins = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/top-coins`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCoinList(data.coinIds); // Use the original coinIds format
        setLoading(false);
      } catch (err) {
        console.error('Error fetching top coins:', err);
        setError('Failed to load top coins');
        setLoading(false);
      }
    };

    fetchTopCoins();
  }, []);

  // Memoize the dropdown options
  const dropdownOptions = useMemo(
    () =>
      coinList.map((coin) => ({
        value: coin.id,
        label: `${coin.name} (${coin.symbol.toUpperCase()})`,
      })),
    [coinList]
  );

  // Set default selected coin to the first coin in the list when coins are loaded
  useEffect(() => {
    if (!loading && coinList.length > 0 && !selectedCoin) {
      setSelectedCoin(coinList[0]);
    }
  }, [loading, coinList, selectedCoin, setSelectedCoin]);

  if (loading) return <p>Loading top coins...</p>;
  if (error) return <p>{error}</p>;

  const handleChange = (e) => {
    const selectedId = e.target.value;
    const selected = coinList.find((coin) => coin.id === selectedId);
    setSelectedCoin(selected || null);
  };

  return (
    <DynamicDropdown
      value={selectedCoin ? selectedCoin.id : coinList.length > 0 ? coinList[0].id : ''}
      onChange={handleChange}
      options={dropdownOptions} // Pass the memoized options
      placeholder="Select a Coin"
    />
  );
};

export default CoinDropdown;
