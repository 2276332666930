// src/components/WalletConnectButton.js

import React from 'react';
import './WalletConnectButton.css'; // Import the CSS file

const WalletConnectButton = ({ account, connectWallet, isConnecting }) => (
  <div className="wallet-connect-container">
    {account ? (
      <p className="connected-account">Connected account: {account}</p>
    ) : (
      <button 
        className="wallet-connect-button" 
        onClick={connectWallet} 
        disabled={isConnecting}
        aria-label="Connect your wallet"
      >
        <i className="fas fa-wallet"></i> {/* Wallet Icon */}
        {isConnecting ? 'Connecting...' : 'Connect Wallet'}
      </button>
    )}
  </div>
);

export default WalletConnectButton;
