// src/config/networks.js

export const networks = {
  songbird: {
    chainId: '0x13',
    chainName: 'Songbird',
    symbol: 'SGB',
    decimals: 18,
    contractAddress: '0xe6222145426C1C47dA910C22cd08aC72E0228Da6',
    fotonTokenAddress: '0x8B1EA814149d533330b2D0BB15d797d937F9948C',
    paymentAmount: '10',
    rpcUrl: 'https://songbird-api.flare.network/ext/C/rpc',
    blockExplorerUrl: 'https://songbird-explorer.flare.network',
    enabled: false,
  },
  flare: {
    chainId: '0xe',
    chainName: 'Flare',
    symbol: 'FLR',
    decimals: 18,
    contractAddress: '0x0654F9503D92590B0637eb61F1462a202e3aE305',
    paymentAmount: '5',
    rpcUrl: 'https://flare-api.flare.network/ext/C/rpc',
    blockExplorerUrl: 'https://flare-explorer.flare.network',
    enabled: true,
    fotonTokenAddress: '0x8B1EA814149d533330b2D0BB15d797d937F9948C',
  },
  coston2: {
    chainId: '0x72',
    chainName: 'Coston2',
    symbol: 'C2FLR',
    decimals: 18,
    contractAddress: '0xEcd0fAbb2825814B480156a8886fBE21D2245d97',
    paymentAmount: '5',
    rpcUrl: 'https://coston2-api.flare.network/ext/C/rpc',
    blockExplorerUrl: 'https://coston2-explorer.flare.network',
    enabled: false,
    pyroTokenAddress: '0x469dd82993185fb3274c1A53E517ff29454f8a6e',
  },
  ethereum: {
    chainId: '0x1',
    chainName: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    contractAddress: '0xfBe49bFF9187af5821091821699Be327bE05Ce9B',
    paymentAmount: '0.0004',
    rpcUrl: 'https://ethereum-rpc.publicnode.com',
    blockExplorerUrl: 'https://etherscan.io',
    enabled: false,
  },
  avalanche: {
    chainId: '0xa86a',
    chainName: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
    contractAddress: '0x87aa60EA60Ed960deD99D41fd88A99F9d1F810EA',
    paymentAmount: '0.005',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockExplorerUrl: 'https://snowtrace.io',
    enabled: false,
  },
};
