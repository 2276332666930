import React from 'react';
import './AlgorithmInfo.css';

const AlgorithmInfo = () => {
  return (
    <div className="algorithm-info">
      <h2>Price Predictor by Pythian Oracle</h2>
      <p>
        Welcome to the Deep-Learning-Powered cryptocurrency price prediction tool provided by <strong>Pythian Oracle</strong>! As part of our comprehensive Web3 services, we utilize advanced machine learning algorithms to help you make informed decisions in the dynamic crypto market.
      </p>
      <h3>How It Works</h3>
      <p>
        Our technology employs a deeply architected <strong>Neural Network</strong> designed for accurate price predictions in time series data. Trained on hundreds of datasets from both stock and crypto markets, our AI model analyzes historical trends and market patterns to forecast tomorrow's prices for your favorite cryptocurrencies.
      </p>
      <h3>Why Choose Pythian Oracle?</h3>
      <ul>
        <li><strong>Web3 Expertise:</strong> Benefit from Pythian Oracle's cutting-edge solutions in blockchain and cryptocurrency technologies.</li>
        <li><strong>Advanced Neural Networks:</strong> Experience high-accuracy predictions powered by deep learning techniques.</li>
        <li><strong>User-Friendly & Affordable:</strong> Enjoy an easy-to-use platform with minimal costs, making advanced AI accessible to everyone.</li>
        <li><strong>Verified Smart Contracts:</strong> Interact with secure and transparent smart contracts verified on the Flare network.</li>
      </ul>
      <h3>Verified Smart Contracts</h3>
      <p>
        We prioritize transparency and security. Our smart contracts are verified and available for public viewing:
      </p>
      <ul>
        <li>
          <strong>Price Predictor Contract:</strong>{' '}
          <a
            href="https://routescan.io/address/0x0654F9503D92590B0637eb61F1462a202e3aE305/contract/14/code"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Price Predictor Contract Flare Explorer
          </a>
        </li>
      </ul>
      <h3>Get Started with Pythian Oracle</h3>
      <p>
        Ready to dive in? Connect your wallet, select a coin, and discover our AI's predictions for tomorrow's prices!
      </p>
      <p>
        <strong>Disclaimer:</strong> Predictions are based on historical data and AI models. They are not financial advice.
        Please do your own research and consider consulting a financial advisor before making investment decisions.
      </p>
    </div>
  );
};

export default AlgorithmInfo;
