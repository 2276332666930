// src/components/NetworkIndicator.jsx

import React from 'react';

const NetworkIndicator = ({ network }) => (
  <div className="network-indicator">
    <p>Connected to: {network.chainName}</p>
  </div>
);

export default NetworkIndicator;
