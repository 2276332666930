// src/components/DynamicDropdown.js

import React, { useEffect, useRef } from 'react';
import './Dropdown.css'; // Reuse the same CSS file

const DynamicDropdown = ({ value, onChange, options, placeholder }) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const adjustWidth = () => {
      const select = selectRef.current;
      const tempElement = document.createElement('span');
      tempElement.style.visibility = 'hidden';
      tempElement.style.position = 'absolute';
      tempElement.style.fontFamily = window.getComputedStyle(select).fontFamily;
      tempElement.style.fontSize = window.getComputedStyle(select).fontSize;

      document.body.appendChild(tempElement);

      let maxWidth = 0;
      options.forEach(option => {
        tempElement.innerText = option.label;
        const width = tempElement.getBoundingClientRect().width;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      select.style.width = `${maxWidth + 40}px`; // Add padding for aesthetics
      document.body.removeChild(tempElement);
    };

    adjustWidth();

    window.addEventListener('resize', adjustWidth); // Recalculate on window resize

    return () => {
      window.removeEventListener('resize', adjustWidth);
    };
  }, [options]);

  return (
    <div className="select-container">
      <select
        ref={selectRef}
        value={value}
        onChange={onChange}
        className="custom-dropdown"
      >
        {placeholder && !value && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DynamicDropdown;
