// src/components/WithdrawButton.js

import React from 'react';

const WithdrawButton = ({ withdrawFunds }) => {
  return (
    <button onClick={withdrawFunds}>Withdraw Funds</button>
  );
};

export default WithdrawButton;
