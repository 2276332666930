// src/App.js

import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { ethers } from 'ethers';
import WalletConnectButton from './components/WalletConnectButton';
import PaymentForm from './components/PaymentForm';
import WithdrawButton from './components/WithdrawButton';
import CoinDropdown from './components/CoinDropdown';
import ModelDropdown from './components/ModelDropdown';
import PredictionTable from './components/PredictionTable';
import SocialMediaIcons from './components/SocialMediaIcons';
import AlgorithmInfo from './components/AlgorithmInfo';
import { contractABI } from './utils/contractABI';
import { getCurrentNetwork } from './utils/getCurrentNetwork';
import NetworkIndicator from './components/NetworkIndicator';
import NetworkSwitcher from './components/NetworkSwitcher';
import './App.css';
import background from './assets/background.jpg';
import fotonTokenABI from './utils/fotonTokenABI';

function App() {
  // State variables
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [predictedPrice, setPredictedPrice] = useState(null);
  const [owner, setOwner] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [loadingPrediction, setLoadingPrediction] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Connect to MetaMask
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        // Request account access
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];
        setAccount(walletAddress);

        // Create provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Get current network
        const network = await getCurrentNetwork();
        setCurrentNetwork(network);

        if (!network) {
          alert('Unsupported network. Please switch to a supported network.');
          return;
        }

        // Create contract instance using the current network's contract address
        const contractInstance = new ethers.Contract(network.contractAddress, contractABI, signer);
        setContract(contractInstance);

        // Fetch the owner from the contract
        const contractOwner = await contractInstance.owner();
        setOwner(contractOwner.toLowerCase());

        // Fetch predictions for this wallet
        fetchPredictions(walletAddress);
      } catch (error) {
        console.error('Error connecting wallet:', error);
        alert('Failed to connect wallet.');
      }
    } else {
      alert('Please install MetaMask to use this app.');
    }
  };

  // Fetch predictions for a wallet address
  const fetchPredictions = async (walletAddress) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/predictions/${walletAddress}`);
      if (!response.ok) {
        throw new Error('Failed to fetch predictions.');
      }
      const data = await response.json();
      setPredictions(data.predictions);
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  };

  // Request payment and handle prediction
  const requestPayment = async (paymentMethod) => {
    if (!contract) {
      alert('Please connect your wallet first.');
      return;
    }

    if (!selectedCoin) {
      alert('Please select a coin from the dropdown.');
      return;
    }

    if (selectedModel === null) {
      alert('Please select a prediction model from the dropdown.');
      return;
    }

    if (!currentNetwork) {
      alert('Unsupported network. Please switch to a supported network.');
      return;
    }

    try {
      // Check if the user's wallet is on the correct network
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const network = await provider.getNetwork();
      const chainIdHex = `0x${network.chainId.toString(16)}`;

      if (chainIdHex.toLowerCase() !== currentNetwork.chainId.toLowerCase()) {
        alert(`Please switch to ${currentNetwork.chainName} network.`);
        return;
      }

      setAwaitingConfirmation(true); // Set awaiting confirmation to true

      let tx;
      if (paymentMethod === 'FLR') {
        // Send transaction to smart contract to make payment in FLR
        tx = await contract.makePaymentInFLR({
          value: ethers.utils.parseUnits(currentNetwork.paymentAmount, currentNetwork.decimals),
          gasLimit: 100000, // Adjust if necessary
        });
      } else if (paymentMethod === 'FOTON') {
        // Approve FOTON token spending if not already approved
        const fotonTokenContract = new ethers.Contract(
          currentNetwork.fotonTokenAddress,
          fotonTokenABI,
          signer
        );

        const requiredAmount = ethers.utils.parseUnits('50', 18);
        const allowance = await fotonTokenContract.allowance(account, currentNetwork.contractAddress);

        if (allowance.lt(requiredAmount)) {
          const approveTx = await fotonTokenContract.approve(currentNetwork.contractAddress, requiredAmount);
          console.log('Approval transaction sent:', approveTx.hash);
          await approveTx.wait();
          console.log('Approval transaction confirmed:', approveTx.hash);
        }

        // Send transaction to smart contract to make payment in FOTON
        tx = await contract.makePaymentInFOTON({
          gasLimit: 200000, // Adjusted gas limit if necessary
        });
      }

      console.log('Payment transaction sent:', tx.hash);

      // Wait for transaction to be mined
      await tx.wait();

      console.log('Payment transaction confirmed:', tx.hash);

      // Reset awaiting confirmation
      setAwaitingConfirmation(false);

      // Send data to backend
      await sendCoinIdAndModelToBackend(
        selectedCoin.id,
        selectedModel,
        tx.hash,
        currentNetwork.chainId,
        paymentMethod
      );

      alert('Payment successful! Prediction request sent.');
    } catch (error) {
      console.error('Error during payment:', error);
      alert('Payment failed. See console for details.');
      setAwaitingConfirmation(false); // Reset awaiting confirmation on error
    }
  };

  // Send data to backend
  const sendCoinIdAndModelToBackend = async (coinId, model, transactionHash, chainId, paymentMethod) => {
    setLoadingPrediction(true);
    try {
      const response = await fetch(`${BACKEND_URL}/api/request-prediction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          coinId,
          model,
          transactionHash,
          walletAddress: account,
          chainId,
          paymentMethod,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend error response:', errorText);
        throw new Error('Failed to send data to backend.');
      }

      const data = await response.json();
      console.log('Backend response:', data);

      setPredictedPrice(data.predictedPrice);
      // Fetch updated predictions
      fetchPredictions(account);
    } catch (error) {
      console.error('Error sending data to backend:', error);
      alert('Failed to send data to backend.');
    } finally {
      setLoadingPrediction(false);
    }
  };

  // Withdraw funds from the contract
  const withdrawFunds = async () => {
    if (!contract) {
      alert('Please connect your wallet first.');
      return;
    }

    if (!currentNetwork) {
      alert('Unsupported network. Please switch to a supported network.');
      return;
    }

    try {
      // Check if the user's wallet is on the correct network
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      const chainIdHex = `0x${network.chainId.toString(16)}`;

      if (chainIdHex.toLowerCase() !== currentNetwork.chainId.toLowerCase()) {
        alert(`Please switch to ${currentNetwork.chainName} network.`);
        return;
      }

      const tx = await contract.withdraw();
      console.log('Withdrawal transaction sent:', tx.hash);

      // Wait for transaction to be mined
      await tx.wait();
      console.log('Withdrawal transaction confirmed:', tx.hash);

      alert('Funds withdrawn successfully!');
    } catch (error) {
      console.error('Error during withdrawal:', error);
      if (error.code === 4001) {
        // User rejected the transaction
        alert('Withdrawal cancelled by user.');
      } else {
        alert('Withdrawal failed. See console for details.');
      }
    }
  };

  useEffect(() => {
    const fetchNetwork = async () => {
      const network = await getCurrentNetwork();
      setCurrentNetwork(network);
    };
    fetchNetwork();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });

      window.ethereum.on('accountsChanged', (accounts) => {
        setAccount(accounts[0] || null);
      });
    }
  }, []);

  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <div className="content">
        <h1>Pythian Price Predictor</h1>
        <NetworkSwitcher currentNetwork={currentNetwork} />
        {currentNetwork && <NetworkIndicator network={currentNetwork} />}
        <nav className="navigation">
          <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')}>
            Predictor
          </NavLink>
          <NavLink to="/algorithm-info" className={({ isActive }) => (isActive ? 'active' : '')}>
            About
          </NavLink>
        </nav>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <WalletConnectButton account={account} connectWallet={connectWallet} />
                {account && (
                  <>
                    <CoinDropdown selectedCoin={selectedCoin} setSelectedCoin={setSelectedCoin} />
                    <ModelDropdown selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
                    {currentNetwork && (
                      <PaymentForm
                        requestPayment={requestPayment}
                        loading={loadingPrediction}
                        isDisabled={!selectedCoin || selectedModel === null}
                        network={currentNetwork}
                        account={account}
                        awaitingConfirmation={awaitingConfirmation}
                      />
                    )}
                    {loadingPrediction && <p>Oracle Predicting...</p>}
                    {predictedPrice && selectedCoin && (
                      <div className="predicted-price">
                        <h2>
                          Tomorrow's Predicted Price For {selectedCoin.symbol.toUpperCase()}: ${predictedPrice}
                        </h2>
                      </div>
                    )}
                    {predictions.length > 0 ? (
                      <PredictionTable predictions={predictions} />
                    ) : (
                      <p>No previous predictions to display.</p>
                    )}
                    {account.toLowerCase() === owner && <WithdrawButton withdrawFunds={withdrawFunds} />}
                    {/* Social Media Icons */}
                    <SocialMediaIcons />
                    {/* Optional: Add a footer section */}
                    <footer className="App-footer">
                      <p>
                        &copy; {new Date().getFullYear()} Pythian Price Predictor, provided by Pythian Oracle. All
                        rights reserved.
                      </p>
                    </footer>
                  </>
                )}
              </>
            }
          />
          <Route path="/algorithm-info" element={<AlgorithmInfo />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
