// src/components/SocialMediaIcons.jsx

import React from 'react';
import './SocialMediaIcons.css'; // Import the CSS file for styling

const SocialMediaIcons = () => {
  return (
    <div className="social-media-container">
      {/* X */}
        <a 
            href="https://x.com/Pythian_Oracle?lang=en" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-icon"
            aria-label="X"
            >
            <img 
                src={require('../assets/x-icon.png')} 
                alt="X icon" 
                style={{ width: '24px', height: '24px' }} 
            />
        </a>
      
      
    </div>
  );
};

export default SocialMediaIcons;
