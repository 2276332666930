// src/components/ModelDropdown.js

import React, { useEffect, useMemo } from 'react';
import DynamicDropdown from './DynamicDropdown'; // Import the new component

const ModelDropdown = ({ selectedModel, setSelectedModel }) => {
  // Memoize the options array to prevent it from changing on every render
  const options = useMemo(
    () => [
      { value: 0, label: 'Baseline Model' },
      { value: 1, label: 'Multi-Model Approach' },
    ],
    []
  );

  // Set default selected value to the first option if not already selected
  useEffect(() => {
    if (selectedModel === null || selectedModel === undefined) {
      setSelectedModel(options[0].value);
    }
  }, [selectedModel, setSelectedModel, options]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedModel(selectedValue === '' ? null : parseInt(selectedValue, 10)); // Ensure correct integer value is passed
  };

  return (
    <DynamicDropdown
      value={selectedModel !== null ? selectedModel : options[0].value} // Ensure correct value is set
      onChange={handleChange}
      options={options} // Pass the model options correctly
      placeholder="Select a Prediction Model"
    />
  );
};

export default ModelDropdown;
