// src/utils/getCurrentNetwork.js

import { networks } from '../config/networks';

export const getCurrentNetwork = async () => {
  if (window.ethereum) {
    const chainIdHex = await window.ethereum.request({ method: 'eth_chainId' });
    const network = Object.values(networks).find(
      (net) => net.chainId.toLowerCase() === chainIdHex.toLowerCase() && net.enabled
    );
    return network;
  }
  return null;
};
