// src/components/NetworkSwitcher.jsx

import React from 'react';
import { networks } from '../config/networks';

const NetworkSwitcher = ({ currentNetwork }) => {
  const switchNetwork = async (network) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }],
      });
    } catch (error) {
      if (error.code === 4902) {
        // Network not added to MetaMask, add it
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: network.chainId,
                chainName: network.chainName,
                nativeCurrency: {
                  name: network.symbol,
                  symbol: network.symbol,
                  decimals: network.decimals,
                },
                rpcUrls: [network.rpcUrl],
                blockExplorerUrls: [network.blockExplorerUrl],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add network:', addError);
        }
      } else {
        console.error('Failed to switch network:', error);
      }
    }
  };

  if (!currentNetwork) {
    return (
      <div className="network-warning">
        <p>Unsupported network detected. Please switch to one of the supported networks:</p>
        {Object.values(networks)
          .filter((network) => network.enabled) // Only show enabled networks
          .map((network) => (
            <button key={network.chainId} onClick={() => switchNetwork(network)}>
              Switch to {network.chainName}
            </button>
          ))}
      </div>
    );
  }

  return null;
};

export default NetworkSwitcher;
